import * as React from "react";
import { cn } from "~/utils/cn";
import { inputClassName } from "./input.styles";
import { Icon, IconKind } from "../../icon";
import { InputProps } from "./input.types";
import { Tooltip, TooltipProvider, DefaultInfoIcon } from "../../tooltip"; // Import the Tooltip component

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      label,
      error,
      helpMessage,
      wordCount,
      startAdornment,
      endAdornment,
      disabled,
      small,
      medium,
      setValue,
      tooltip, // Add tooltip prop
      disableNegative,
      ...props
    },
    ref,
  ) => {
    const {
      textAuxStyles,
      labelStyles,
      inputContainerStyles,
      inputStyles,
      iconStyle,
      startAdornmentStyles,
      endAdornmentStyles,
    } = inputClassName({
      error: !!error,
      disabled,
      small: !!small,
      medium: !!medium,
      startAdornment: !!startAdornment,
      endAdornment: !!endAdornment,
    });

    return (
      <div className="w-full">
        <TooltipProvider>
          <div className="flex items-center gap-1">
            <label
              className={labelStyles()}
              htmlFor={props.id}
              aria-label={label}
            >
              {label}
            </label>

            {tooltip && (
              <Tooltip content={tooltip} tooltipTrigger={<DefaultInfoIcon />} />
            )}
          </div>
        </TooltipProvider>
        <div className={inputContainerStyles()}>
          {!!startAdornment && (
            <div className={startAdornmentStyles()}>
              <Icon
                aria-label="startAdornment"
                {...startAdornment}
                icon={cn(startAdornment.icon, iconStyle())}
                disabled={disabled}
                kind={startAdornment.kind ?? IconKind.normal}
              />
            </div>
          )}
          <input
            type={type}
            min={disableNegative && type === "number" ? 0 : undefined}
            className={inputStyles({
              startAdornment: !!startAdornment,
              endAdornment: !!endAdornment,
            })}
            ref={ref}
            disabled={disabled}
            onChange={e => setValue?.(props.name ?? "", e.target.value)}
            aria-label={props["aria-label"] ?? props.placeholder}
            {...props}
          />
          {!!endAdornment && (
            <div className={endAdornmentStyles()}>
              <Icon
                aria-label="endAdornment"
                {...endAdornment}
                disabled={disabled}
                icon={cn(endAdornment.icon, iconStyle())}
                kind={endAdornment.kind ?? IconKind.normal}
              />
            </div>
          )}
        </div>
        <div className="flex justify-between">
          <div>
            {error ? (
              <span className="mt-1 inline-block text-xs text-status-critical">
                {error}
              </span>
            ) : (
              helpMessage && (
                <span className={textAuxStyles()}>{helpMessage}</span>
              )
            )}
          </div>
          <div>
            {wordCount && (
              <span className={textAuxStyles()}>
                {(props.value as string)?.length}/{wordCount}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  },
);

Input.displayName = "Input";

export { Input };
