import { tv } from "tailwind-variants";
import { InputVariants } from "./input-editor.types";

export const inputEditorClassName = tv({
  slots: {
    labelStyles: "text-neutral-primary text-sm/6",

    textAuxStyles: "text-xs text-neutral-secondary inline-block mt-1",
  },
  variants: {
    [InputVariants.disabled]: {
      true: {
        labelStyles: "text-neutral-disabled",
        textAuxStyles: "text-neutral-disabled",
      },
    },
  },
});
