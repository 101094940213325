import React from "react";
import { cn } from "~/utils/cn";
import { IconKind, IconProps } from "./icon.types";
import { iconClassName } from "./icon.styles";

export const Icon: React.FC<IconProps> = React.forwardRef<HTMLButtonElement>(
  (
    {
      IconElement,
      size = 16,
      className,
      "aria-label": ariaLabel,
      id,
      ...props
    }: IconProps,
    forwardedRef,
  ) => {
    if (IconElement && props.kind === IconKind.normal) {
      return (
        <IconElement size={size} className={className} id={id} {...props} />
      );
    }

    return (
      <button
        data-testid={props["data-testid"]}
        data-disabled={props["data-disabled"]}
        data-state={props["data-state"]}
        className={cn(iconClassName, className)}
        onClick={props.onClick}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onKeyDown={props.onKeyDown}
        onPointerDown={props.onPointerDown}
        onPointerLeave={props.onPointerLeave}
        onPointerMove={props.onPointerMove}
        disabled={props.disabled}
        title={props.title}
        type="button"
        aria-label={ariaLabel}
        aria-controls={props["aria-controls"]}
        aria-describedby={props["aria-describedby"]}
        aria-expanded={props["aria-expanded"]}
        aria-haspopup={props["aria-haspopup"]}
        id={id}
        ref={forwardedRef}
      >
        {props.icon ? (
          <i aria-hidden className={props.icon} />
        ) : (
          IconElement && <IconElement size={size} {...props} />
        )}
      </button>
    );
  },
);
