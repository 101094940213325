import { IconProps } from "../../icon";

export enum InputVariants {
  disabled = "disabled",
  error = "error",
  small = "small",
  medium = "medium",
  startAdornment = "startAdornment",
  endAdornment = "endAdornment",
}

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  helpMessage?: string;
  wordCount?: number;
  startAdornment?: IconProps;
  endAdornment?: IconProps;
  small?: boolean;
  medium?: boolean;
  setValue?: (name: string, value: string) => void;
  tooltip?: string;
  disableNegative?: boolean;
}
