import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useController, Control } from "react-hook-form";

interface RichTextEditorProps {
  name: string;
  control: Control<any>;
  defaultValue?: string;
  error?: string;
  setValue?: (name: string, value: string) => void;
  value?: string;
}

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
  name,
  control,
  defaultValue = "",
  error,
  setValue,
  value: initialContent,
}) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const handleEditorChange = (content: string) => {
    onChange(content);
    setValue && setValue(name, content);
  };

  return (
    <div>
      <Editor
        apiKey={process.env.NEXT_PUBLIC_TINYMCE_API_KEY}
        value={initialContent ?? value}
        onEditorChange={setValue ? handleEditorChange : onChange}
        init={{
          height: 300,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help",
        }}
      />
      {error && <span className="text-sm text-red-500">{error}</span>}
    </div>
  );
};
