import React from "react";
import { RichTextEditor } from "./rich-text-editor";
import { InputEditorProps } from "./input-editor.types";
import { inputEditorClassName } from "./input-editor.styles";

export const InputEditor: React.FC<InputEditorProps> = ({
  label,
  error,
  helpMessage,
  setValue,
  initialContent,
  wordCount,
  disabled,
  value,
  ...props
}) => {
  const { labelStyles } = inputEditorClassName({
    disabled,
  });

  return (
    <div className="w-full">
      <label className={labelStyles()} htmlFor={props.name} aria-label={label}>
        {label}
      </label>

      <RichTextEditor
        name={props.name || ""}
        control={props.control}
        defaultValue={initialContent}
        setValue={setValue}
        error={error}
        value={value}
      />
      {helpMessage && !error && (
        <span className="mt-1 text-sm text-gray-500">{helpMessage}</span>
      )}
    </div>
  );
};
