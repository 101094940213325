import React from "react";
import { cn } from "~/utils/cn";

import {
  ButtonAlign,
  ButtonKind,
  ButtonProps,
  ButtonStandaloneKind,
  ButtonWidth,
} from "./button.types";
import { standaloneButtonClassName, buttonClassName } from "./styles";

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      text,
      onClick,
      disabled,
      size,
      width,
      children,
      link,
      asChild,
      loading = false,
      startAdornment,
      endAdornment,
      isStandalone = false,
      kind,
      ...props
    },
    ref,
  ) => {
    const className = isStandalone
      ? standaloneButtonClassName({
          kind: kind as ButtonStandaloneKind,
          size,
          width,
        })
      : buttonClassName({ kind: kind as ButtonKind, size, width });
    const elementProps = {
      className,
      onClick,
      disabled: disabled || loading,
      "aria-label": props["aria-label"] ?? props.title,
    };
    const content = (
      <div
        className={cn("relative flex w-full items-center gap-3", {
          "justify-center": props.align === ButtonAlign.center || width === ButtonWidth.full,
          "justify-start": props.align === ButtonAlign.left,
          "justify-end": props.align === ButtonAlign.right,
        })}
      >
        {startAdornment && (
          <i
            aria-hidden
            aria-label="startAdornment"
            className={cn(startAdornment, "fa-1x")}
          />
        )}
        {!loading ? (
          text ?? children
        ) : (
          <div className="absolute w-full justify-center">
            <i
              aria-hidden
              aria-label="loader"
              className={cn("fa-light fa-spinner-scale fa-spin-pulse")}
            />
          </div>
        )}
        {endAdornment && (
          <i
            aria-hidden
            aria-label="endAdornment"
            className={cn(endAdornment, "fa-1x")}
          />
        )}
      </div>
    );

    return asChild ? (
      <div {...elementProps}>{content}</div>
    ) : (
      <button ref={ref} {...elementProps} {...props}>
        {content}
      </button>
    );
  },
);
