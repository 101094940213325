import { SetValueConfig } from "react-hook-form";
import { Control } from "react-hook-form";

export enum InputVariants {
  disabled = "disabled",
  error = "error",
}

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  helpMessage?: string;
  wordCount?: number;
  small?: boolean;
  setValue: (name: string, value: string, config?: SetValueConfig) => void;
  value?: string;
  initialContent?: string;
}

export interface InputEditorProps {
  disabled?: boolean;
  wordCount?: number;
  id?: string;
  label?: string;
  error?: string;
  helpMessage?: string;
  setValue?: (name: string, value: string) => void;
  initialContent?: string;
  name: string;
  control: Control<any>;
  className?: string;
  value?: string;
}
