"use client";

import React, { KeyboardEventHandler, useRef } from "react";
import { cn } from "~/utils/cn";
import { Input } from "../default";
import { InputSearchProps } from "./input-search.types";

export const InputSearch: React.FC<InputSearchProps> = ({
  onChangeSearch,
  search,
  hideSearchIcon = false,
  onPressEnter,
  icon,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const clearSearch = () => {
    const input = inputRef.current;

    if (input) {
      input.value = "";
    }

    onChangeSearch("");
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.key === "Enter") {
      onPressEnter?.();
    }
  };

  return (
    <Input
      ref={inputRef}
      type="search"
      aria-label="Search input"
      onChange={event => onChangeSearch(event.target.value)}
      value={search}
      onKeyDown={onKeyDown}
      startAdornment={{
        icon: icon,
        IconElement: !icon
          ? () => <i aria-hidden className="fa-regular fa-magnifying-glass" />
          : undefined,
      }}
      endAdornment={{
        icon: "fa-regular fa-xmark",
        "aria-label": "Clear search",
        onClick: () => clearSearch(),
        className: cn(
          "flex text-icon-neutral-20 opacity-100 transition-opacity duration-150",
          {
            "pointer-events-none opacity-0": !search,
          },
        ),
      }}
      {...props}
    />
  );
};
