import { tv } from "tailwind-variants";
import { cn } from "~/utils/cn";

export const tagStyle = tv({
  slots: {
    tag: cn(
      "flex h-8 w-fit select-none flex-nowrap items-center gap-2 rounded-full bg-neutral-20 px-3",
    ),
    contentContainer: "flex items-baseline gap-2",
    icons: "fa-sm",
    textContent: "text-sm font-medium tracking-tighter",
    closeIcon: "fa-solid fa-xmark fa-sm cursor-pointer text-icon-neutral-20",
  },
  variants: {
    small: {
      true: {
        tag: "h-6 px-2",
        contentContainer: "gap-1 text-xs tracking-[-0.3px]",
        icons: "fa-xs",
        textContent: "text-xs",
        closeIcon: "fa-xs",
      },
    },
  },
});
