import { tv } from "tailwind-variants";
import { cn } from "~/utils/cn";
import { ButtonSize, ButtonStandaloneKind } from "../button.types";
import { baseButton } from "./base-button.styles";

export const standaloneButtonClassName = tv({
  extend: baseButton,
  base: "bg-transparent disabled:bg-transparent disabled:text-neutral-disabled",
  variants: {
    kind: {
      [ButtonStandaloneKind.brand]: cn(
        "text-brand-primary-rest",
        "hover:bg-brand-primary-light-00",
        "active:bg-brand-primary-light-10",
      ),
      [ButtonStandaloneKind.subtle]: cn(
        "text-neutral-primary",
        "hover:bg-brand-primary-light-00",
        "active:bg-brand-primary-light-10",
      ),
      [ButtonStandaloneKind.critical]: cn(
        "text-status-critical",
        "hover:bg-neutral-20",
        "active:bg-neutral-30",
      ),
    },
    size: {
      [ButtonSize.medium]: "py-2 px-3",
    },
  },
  defaultVariants: {
    kind: ButtonStandaloneKind.brand,
  },
});
