import { IconProps as TablerIconProps, Icon } from "@tabler/icons-react";

export enum IconKind {
  normal = "normal",
  button = "button",
}

interface IconBaseProps<K extends IconKind>
  extends CustomOmit<
    TablerIconProps,
    | "onClick"
    | "onBlur"
    | "onFocus"
    | "onKeyDown"
    | "onPointerDown"
    | "onPointerLeave"
    | "onPointerMove"
  > {
  kind?: K;
  IconElement?: Icon;
  icon?: string;
  onClick?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onKeyDown?: () => void;
  onPointerDown?: () => void;
  onPointerLeave?: () => void;
  onPointerMove?: () => void;
  disabled?: boolean;
  ["data-testid"]?: string;
  ["data-disabled"]?: string;
  ["data-state"]?: string;
}

type IconNormalProps = IconBaseProps<IconKind.normal>;

interface IconButtonProps extends IconBaseProps<IconKind.button> {}

export type IconProps = IconNormalProps | IconButtonProps;
