import { cn } from "~/utils/cn";
import { tagStyle } from "./tag.styles";
import { TagProps } from "./tag.types";

export const Tag: React.FC<TagProps> = ({
  closeButton,
  text,
  small,
  startAdornment,
  endAdornment,
  ...props
}) => {
  const { closeIcon, contentContainer, icons, tag, textContent } = tagStyle({
    small,
  });

  return (
    <div className={tag({ class: props.className })}>
      <div className={contentContainer()}>
        {startAdornment && (
          <i
            aria-label="startAdornment"
            className={cn(startAdornment, icons())}
          />
        )}

        <div className={textContent()} aria-label={props["aria-label"]}>
          {text}
        </div>

        {endAdornment && (
          <i aria-label="endAdornment" className={cn(endAdornment, icons())} />
        )}

        {closeButton?.hasCloseButton && (
          <i
            role="button"
            className={closeIcon()}
            onClick={closeButton?.onClose}
          />
        )}
      </div>
    </div>
  );
};
